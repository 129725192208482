import * as React from 'react'
import SmallBanner from '../components/SmallBanner'
import Container from '../components/Container'
import styled from 'styled-components'
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { breakpoint, colors } from '../styles/variables'
import { Link } from 'gatsby'


const Wrapper = styled(Container)`
  min-height: 100vh;
  margin-top: 2rem;
  margin-bottom: 3rem;
  max-width: 700px;
  padding: 0 1rem 2rem;

  @media ${breakpoint.sm} {
    margin-top: 3rem;
  }

  @media ${breakpoint.md} {
    margin-top: 4.5rem;
    margin-bottom: 5rem;
  }

  @media ${breakpoint.lg} {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
`

const Title = styled.h2 `
    margin-top: 0;
    text-transform: none;
    margin-bottom: 0.5em;
    font-size: 1.75rem;
`

const StyledFigure = styled.figure `
    margin: 1.5rem 0 2rem;

    @media ${breakpoint.md} {
        margin: 2rem 0 2.5rem;
    }
`

const StyledFigcaption = styled.figcaption `
    font-size: 0.875rem;
    color: gray;
    margin-top: 0.5em;
    text-align: right;
`

const BackLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
  margin-bottom: 2.5rem;
  display: inline-block;
  font-size: 0.875rem;
  transition: color 0.3s ease;

  @media ${breakpoint.lg} {
    margin-bottom: 4rem;
  }

  &:hover {
    color: ${colors.orangeHover};
  }

  .icon {
    margin-right: 0.5rem;
  }
`

const Date = styled.p`
    color: gray;
    margin: 0 0 2rem;
    font-size: 0.875rem;
`

const BlogList = styled.ol `
     padding: 0;
     list-style: none;
    counter-reset: my-awesome-counter;

    h3 {
        font-size: 1rem;
        padding-left: 1.25em;
        margin-bottom: 0.5em;
        font-weight: 700;
    }
    p {
        display: inline;
    }

    li {
        margin: 1.5rem 0;
        line-height: 1.5;
        counter-increment: my-awesome-counter;
        position: relative;

        &::before {
            content: counter(my-awesome-counter) "." ;
            font-weight: 700;
            position: absolute;
        }
    }
`

const DoubleImage = styled.div `
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    margin: 1.5rem 0 2.5rem;
`

const BlogGallery = styled.ul `
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    li {
        height: 125px;
    }
`

const BlogpostPage = ({data, title}) => {

    return (
       <>
        <SmallBanner title="Blog"/>
        <Wrapper>
            <BackLink to="/blog">
                <span className="icon icon-arrow-left"></span>
                Zpět na výpis článků
            </BackLink>
            <Title>7 důvodů, proč miluji Rigu:</Title>
            <Date>5. 12. 2022</Date>
            <BlogList>
                <li>
                    <h3>Hudba</h3>
                    <p>Ať sem vyrazíte v jakémkoliv ročním období, všude v parcích a na ulicích v centru města potkáte hudební umělce. Někteří zpívají, jiní hrají známé (i neznámé) hity na své hudební nástroje.</p>
                </li>
                <li>
                    <h3>Jazyková vybavenost</h3>
                    <p>Jelikož jsem původem z Ukrajiny, tak umím rusky. Hodně lidí v Rize také rusky mluví a pokud ruštinu použijete, automaticky to pro ně neznamená, že jste z Ruska. Vnímají to tak, že jste se rusky jen učili. Zároveň hodně mladých lidí mluví perfektně anglicky a rádi si s Vámi popovídají.</p>
                </li>
                <li>
                    <h3>Mladí lidé</h3>
                    <p>Jako mnoho hlavních měst je Riga studentským městem. Potkáte zde hodně mladých lidí, ať už místní studenty, zahraniční studenty nebo expaty, kteří zde pracují.</p>
                </li>
                <li>
                    <h3>Jídlo</h3>
                    <p>Riga nabízí skvělou kombinaci původní a moderní kuchyně. Saláty, ryby, maso v různých úpravách, zelenina na mnoho způsobů a spousta dalších (i vegetariánských) možností. Já osobně mám nejraději bistro uprostřed trhu Riga: Central Market (místo, které obecně ráda navštěvují) - Centralttirgus Naktstirdzins. Miluji jejich dušené zelí, saláty z červené řepy a lívance na sladko.</p>
                    <DoubleImage>
                        <StaticImage src="../assets/images/blog/riga/riga1.jpg" alt="Stravování v Rize"></StaticImage>
                        <StaticImage src="../assets/images/blog/riga/riga2.jpg" alt="Stravování v Rize"></StaticImage>
                    </DoubleImage>
                </li>
                <li>
                    <h3>Jurmala</h3>
                    <p>Jurmala je přímořské středisko poblíž Rigy. Jedná se o lázeňské město, které v létě žije. Jednoduše se sem z města dostanete vlakem nebo na kole. Pokud se rozhodnete pro kolo, čeká Vás nádherný výlet po rovince. Krásná cyklostezka vede kousek městem, pak cestou podél kolejí a následně lesem. Jako cyklista amatér doporučuji všemi deseti.</p>
                    <DoubleImage>
                        <StaticImage src="../assets/images/blog/riga/riga3.jpg" alt="Jurmala"></StaticImage>
                        <StaticImage src="../assets/images/blog/riga/riga4.jpg" alt="Jurmala"></StaticImage>
                    </DoubleImage>
                </li>
                <li>
                    <h3>Parky</h3>
                    <p>Pokud jste milovníci zeleně a rádi trávíte čas v parcích, Riga je k tomu jako stvořená. Je zde mnoho parků, kde si v krásném prostředí můžete vychutnat šálek kávy nebo skleničku vína, nebo si vyjít na procházku. V některých jsou i cyklostezky, kam můžete vyrazit klidně i na brusle.</p>
                        <StyledFigure>
                            <StaticImage src="../assets/images/blog/riga/riga5.jpg" alt="Park v Rize"></StaticImage>
                        </StyledFigure>
                        <StyledFigure>

                            <StaticImage src="../assets/images/blog/riga/riga6.jpg" alt="Park v Rize"></StaticImage>
                        </StyledFigure>
                </li>
                <li>
                    <h3>Historie</h3>
                    <p>Ta je všudypřítomná, po jejích stopách se nemusíte vydávat pouze ve městě, ale můžete vyrazit i za jeho hranice. Staré domy, muzea plná dějin, historické pevnosti a vesnice a mnoho dalších památek, které stojí za návštěvu.</p>
                    <DoubleImage>
                        <StaticImage src="../assets/images/blog/riga/riga7.jpg" alt="Riga"></StaticImage>
                        <StaticImage src="../assets/images/blog/riga/riga8.jpg" alt="Riga"></StaticImage>
                    </DoubleImage>
                </li>
            </BlogList>

                <StyledFigure>
                    <StaticImage src="../assets/images/blog/riga/riga9.jpg" alt="Riga"></StaticImage>
                    {/* <StyledFigcaption>(Photo by Julia: ostrov Koh Rong - Coconut Beach, Cambodia)</StyledFigcaption> */}
                </StyledFigure>
                <p>Lotyšsko navíc není jen o samotné Rize. Osobně doporučuji na prozkoumání města a okolí minimálně týden, protože nabízí nepřeberné množství možností.</p>

            {/* <BlogGallery>
                <li>
                    <StaticImage src="../assets/images/blog/riga/riga10.jpg" alt="Riga"></StaticImage>
                </li>
                <li>
                    <StaticImage src="../assets/images/blog/riga/riga11.jpg" alt="Riga"></StaticImage>
                </li>
                <li>
                    <StaticImage src="../assets/images/blog/riga/riga12.jpg" alt="Riga"></StaticImage>
                </li>
                <li>
                    <StaticImage src="../assets/images/blog/riga/riga13.jpg" alt="Riga"></StaticImage>
                </li>
                <li>
                    <StaticImage src="../assets/images/blog/riga/riga14.jpg" alt="Riga"></StaticImage>
                </li>
                <li>
                    <StaticImage src="../assets/images/blog/riga/riga15.jpg" alt="Riga"></StaticImage>
                </li>
                <li>
                    <StaticImage src="../assets/images/blog/riga/riga16.jpg" alt="Riga"></StaticImage>
                </li>
                <li>
                    <StaticImage src="../assets/images/blog/riga/riga17.jpg" alt="Riga"></StaticImage>
                </li>
                <li>
                    <StaticImage src="../assets/images/blog/riga/riga18.jpg" alt="Riga"></StaticImage>
                </li>
                <li>
                    <StaticImage src="../assets/images/blog/riga/riga19.jpg" alt="Riga"></StaticImage>
                </li>
            </BlogGallery> */}
        </Wrapper>
       </>
    )
  }

  export default BlogpostPage
